import axios from 'axios';
import { API_NAME } from '../utils/constants';

const API_URL = API_NAME; // L'URL de votre API

const createEntity = async (entityName, entityData) => {
  try {
    const response = await axios.post(`${API_URL}/${entityName}`, entityData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};


const getEntity = async (entityName, entity) => {
  try {
    const response = await axios.get(`${API_URL}/${entityName}/id?id=${entity}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const getLimitedEntities = async (entityName, limit, sortBy) => {
  try {
    if (!limit) limit = 6;
    if (!sortBy) sortBy = "desc";

    const response = await axios.get(`${API_URL}/${entityName}/limited/?limit=${limit}&sortBy=${sortBy}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const getPageEntities = async (entityName, page, limit, sortBy) => {
  try {
    if (!page) page = 1;
    if (!limit) limit = 6;
    if (!sortBy) sortBy = "desc";

    const response = await axios.get(`${API_URL}/${entityName}/page/?page=${page}&limit=${limit}&sortBy=${sortBy}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const updateEntity = async (entityName, entityData) => {
  try {
    const response = await axios.put(`${API_URL}/${entityName}/update`, entityData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const deleteEntity = async (entityName, entityId) => {
  try {
    await axios.delete(`${API_URL}/${entityName}/${entityId}`);
  } catch (error) {
    throw error.response.data.error;
  }
};

const getAllEntities = async (entityName) => {
  try {
    const response = await axios.get(`${API_URL}/${entityName}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const sendImgEntity = async (entityData) => {
  try {
    // Utilisez la bibliothèque Axios ou une autre méthode pour envoyer la requête POST avec les données du formulaire
    const response = await axios.post(`${API_URL}/files/upload`, entityData,  {headers: {
      'Content-Type': 'multipart/form-data'
    }});

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const deleteImgEntity = async ( id, title, p ) => {
  try {
    const response = await axios.get(`${API_URL}/files/delete?id=${id}&title=${title}&p=${p}`, );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

const updateImgEntity = async (entityData) => {
  try {
    // Utilisez la bibliothèque Axios ou une autre méthode pour envoyer la requête POST avec les données du formulaire
    const response = await axios.put(`${API_URL}/files/update`, entityData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const getImgEntity = async (id) => {
  try {
    // Utilisez la bibliothèque Axios 
    const response = await axios.get(`${API_URL}/files/get?id=${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const getAllWithCondition = async ( formData ) => {
  try {
    const response = await axios.post(`${API_URL}/getAllEntities`, formData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

const togleActiveAdminer = async (entityName, idAdminer) => {
  try {
    const response = await axios.get(`${API_URL}/${entityName}/togleActiveAdminer?idAdminer=${idAdminer}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};


export {
  createEntity,
  getEntity,
  getPageEntities,
  getLimitedEntities,
  updateEntity,
  deleteEntity,
  getAllEntities,
  getImgEntity,
  sendImgEntity,
  deleteImgEntity,
  updateImgEntity,
  getAllWithCondition,
  togleActiveAdminer,
};

// Utilisation :
// createEntity('articles', articleData);
// getEntity('articles', articleId);
// getLimitedEntities('articles', limit, sortBy);
// updateEntity('articles', articleId, articleData);
// deleteEntity('articles', articleId);
// getAllEntities('articles');
// Remplacez 'articles' par le nom de l'entité appropriée pour les autres types d'éléments dans votre base de données.
