import React, { useState, useEffect } from "react";


import Layout from "./Layout";
import PageWrapper from "../components/PageWrapper/ArticlesWrapper/PageWrapper";

export const Erreur = () => {
    return (
        <div className="Page z-1">
            <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto mt-[2rem] bg-white">
                <div className="Page_main_content">
                    <div className="Page_main_content_title">
                        <h1>Erreur</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ArticlesViews = () => {
    const [url, setUrl] = useState("");

    useEffect(() => {
       // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let name = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            name = splitUrl[splitUrl.length - 1];
        } 
        if (name === null || name === "" || name === "articles" || name === "undefined") {
            window.location.href = "/notfound";
        }  



        setUrl(name);
    }, []);

    return (
        <Layout>
        <div className="Page z-1">
          <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto mt-[2rem] bg-white">
            <PageWrapper data={url} />
          </div>
        </div>
        </Layout>
    );
}
