import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import { getArticle } from "../../../controllers/articleControllers";
import RenderHtmlString from "../../others/RenderHtmlString";
import { getFirstParagraph, parseToDate } from "../../../utils/othersFunction";

const PHContent = () => {

    const [updatedArticle, setUpdatedArticle] = useState({});
    const [updatedAuteur, setUpdatedAuteur] = useState({})

    const [theRubrique, setTheRubrique] = useState("");
    const [theSousRubrique, setTheSousRubrique] = useState("");

    const [fileUrl, setFileUrl] = useState("");
    
    const [theDate, setDate] = useState("");
    const [theUpdateDate, setUpdateDate] = useState("");

    const [firstParagraph, setFirstParagraph] = useState("");
    const [firstParagra, setFirstParagra] = useState("");

    const [slug, SetSlug] = useState("");

    useEffect(() => {
        // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let name = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            name = splitUrl[splitUrl.length - 1];
        } 
        if (name === null || name === "") {
            window.location.href = "/notfound";
        }  

        getArticle(name)
            .then((res) => {
                if (res) {
                    setUpdatedArticle(res);
                    if(res.files && res.files.length > 0) {
                        // eslint-disable-next-line array-callback-return
                        res.files.map((file, index) => {
                            if(file.is_top) {
                                setFileUrl(`www.laronde.press/files/${file.id_entity}/${file.title}`);
                            }
                        })
                    }
                    // Avoir le slug de l'article
                    if(res.slug) {
                        SetSlug("larondecd.com/articles/" + res.slug);
                        console.log(slug);
                    }
                }else {
                    window.location.href = "/notfound";
                }
            })
    }, []);

    useEffect(() => {
        if(updatedArticle.Categories && updatedArticle.Categories.length > 0) {
            // eslint-disable-next-line array-callback-return
            updatedArticle.Categories.map((category) => {
                if(category.id_parent_categories.length === 0) {
                    setTheRubrique(category);
                } else {
                    setTheSousRubrique(category);
                }
            })
        }
        if(updatedArticle.content) {
            var content = getFirstParagraph(updatedArticle.content);
            setFirstParagraph(content);
            // supprimer les balises html
            const regex = /(<([^>]+)>)/ig;
            const result = content.replace(regex, "");
            setFirstParagra(result);
            console.log(result);
        }

        updatedArticle.created_at ? setDate(parseToDate(updatedArticle.created_at, 'dd-mm..-yyyy')) : setDate("");
        
        updatedArticle.updated_at ? setUpdateDate(parseToDate(updatedArticle.updated_at, 'dd-mm..-yyyy')) : setUpdateDate("");
        
        setUpdatedArticle(updatedArticle)
        setUpdatedAuteur(updatedArticle.author)
    }, [updatedArticle]);



    return (
    <>
    <Helmet>
        <title>{updatedArticle.title}</title>
        <meta property="og:image" content={fileUrl}  />
        <meta property="og:title" content={updatedArticle.title} />
        <meta property="og:description" content={ firstParagra } />
        <meta property="og:type" content="web" />
        <meta property="og:url" content={slug} />
        {/* Ajoutez d'autres balises meta personnalisées si nécessaire */}
    </Helmet>
        <div className="PH_content w-full mb-[20px] px-15px max-w-full">
            <div className="Content_wrapper">
                <ul className="Content_categorises tracking-tight flex flex-wrap text-[#717b8e] text-[1.5rem] items-center uppercase list-none font-normal mb-6 la">
                    <li className="bea Content_categoris flex leading-5 hover:text-black">
                        <a href={`/rubriques/${theRubrique.slug ? theRubrique.slug : theRubrique.name}`} className="mr-2">{theRubrique.name}</a>
                    </li>
                    <li className="bea Content_categoris flex leading-5 hover:text-black">
                        <a href={`/sousrubriques/${theSousRubrique.slug ? theSousRubrique.slug : theSousRubrique.name}`} className="mr-2">{theSousRubrique.name}</a>
                    </li>
                </ul>
                <div className="Content_head max-w-[85%]">
                    <h1 className="Content_title leading-[1.2] font-bold mt-4 text-[2.7rem]">
                        {updatedArticle.title}
                    </h1>
                    <div className="Content_desc text-2xl leading-[1.4] tracking-normal mt-5 font-medium">
                        {updatedArticle.content ? (
                            <RenderHtmlString htmlString={firstParagraph} />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="Content_author text-[1.4rem] mt-[1.2rem] text-[#717b8e]">
                    <div className="m-0 p-0 flex">
                        <span className="w-[60rem]">Par 
                            <span className="Author_link m-2">{updatedAuteur ? updatedAuteur.username : " "}</span>
                        </span>
                    </div>
                </div>
                <div className="Content_date mt-[1.2rem] inline-block text-[1.4rem] text-[#717b8e]">
                    <span>
                    {`Publié le ${theDate}`}
                    <span>
                        {updatedArticle.updated_at ? (
                            <span> - Modifié le {`${theUpdateDate}`} </span>
                        ) : (
                            <></>
                        )}
                    </span>
                    </span>
                </div>
                <div className="Content_status mt-[1.2rem] text-[1.4rem] text-[#717b8e]">
                    {updatedArticle.is_premium ? (
                        <span className="border-[0.03rem] border-solid border-gray-400 overflow-hidden py-2 pr-4">
                            <span className="badge before:content-['LR'] before:h-full before:border-0 before:py-[0.8rem] before:px-[0.4rem] before:text-white"></span>
                            Article premium
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    </>
    );
}

export default PHContent;
