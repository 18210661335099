import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MyRoutes from './routes';

import './styles/output.css';

const App = () => {
  useEffect(() => {
    // Si l'url contient le mot 'admin', on importe les fichiers CSS correspondants

  }, []);

  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
};

export default App;
