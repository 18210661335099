import React, { useEffect, useState } from "react";

import { TeaserRubriqueHeader, TeaserRubriqueHeaderSec } from "../../cards/articleTeaser";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";

const PHContent = () => {


    const [isLoading, setIsLoading] = useState(true);
    const [updatedArticles, setUpdatedArticles] = useState([]);
  
  
    useEffect(() => {
        // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let rubrique = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            rubrique = splitUrl[splitUrl.length - 1];
        }
        if (rubrique === null || rubrique === "") {
            window.location.href = "/notfound";
        }

        console.log(rubrique)
        console.log(window.location.href)

  
      const rubriqueOptions = {
          condition : { slug: rubrique },
          populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
          limit: 1,
          skip: 0,
          projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
          sort: { created_at: -1 },
      }
   
      const sousRubriqueOptions = {
          populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
          limit: 10,
          skip: 0,
          projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
          sort: { created_at: -1 },
      };
  
      const articleOptions = {
          populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
          limit: 10,
          skip: 0,
          projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
          sort: { created_at: -1 },
      };
  
      const fileOptions = 'null'

      let formData = new FormData();
      formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
      formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
      formData.append('articleOptions', JSON.stringify(articleOptions));
      formData.append('fileOptions', JSON.stringify(fileOptions));
  
      getAllWithCondition(formData)
          .then((data) => {
              if (data !== null && data !== undefined && data !== {}) {
                  setUpdatedArticles(data.articles);
                  setIsLoading(false);
              }
          }
          )
    }, []);


    return (
        isLoading ? (
            <p></p>
        ) : (
            <div className="PH_content w-full mml:w-[65%] mb-[20px] px-15px max-w-full">
                <TeaserRubriqueHeader articles={updatedArticles.slice(0, 4)} />
                <TeaserRubriqueHeaderSec articles={updatedArticles.slice(4, 9)} />
            </div>
        )
    );
}

export default PHContent;
