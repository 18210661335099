const models = require('../models/Models');
const Services = require('../services/Services');

const EntityName = 'files';



export const getImgBy = async ( id ) => {
    try{
      // Envoyer les données vers le services
      const response = await Services.getImgEntity(id);
      //Effectuez le traitement souhaité avec la response ici
      if(response){
        return response;
      } else {
        return {};
      }
    } catch (error){
      console.error("Une erreur s\'est produite lors de la suppression de l'image ", error);
    }
  }