import React, { useEffect, useState } from "react";

import handleImageError from "../../../utils/handleImageError";
import RenderHtmlString from "../../others/RenderHtmlString";
import { getArticle } from "../../../controllers/articleControllers";
import { getContentWithoutFirstParagraph } from "../../../utils/othersFunction";

const PB_content = () => {
    const [fileUrl, setFileUrl] = useState("");
    const [content, setContent] = useState("");
    const [updatedArticle, setUpdatedArticle] = useState({});
    const [fileDescription, setFileDescription] = useState("");

    useEffect(() => {
        // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let name = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            name = splitUrl[splitUrl.length - 1];
        } 
        if (name === null || name === "") {
            window.location.href = "/notfound";
        }  

        getArticle(name)
        .then((res) => {
            if (res) {
                if(res.files && res.files.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    res.files.map((file, index) => {
                        if(file.is_top) {
                            setFileUrl(`/api/files/${file.id_entity}/${file.title}`);
                            setFileDescription(file.description);
                        }
                    })
                }
                setUpdatedArticle(res);
            }else {
                window.location.href = "/notfound";
            }
            if(res.content) {
                setContent(getContentWithoutFirstParagraph(res.content));
            }
        })

    }, []);



    return (
        <div className="PB_content justify-start block mml:flex flex-col md:flex-row items-start mml:flex-col mml:self-start w-full mml:w-[65%] pr-8">
            <figure className="Content_media">
                <div className="max-w-[665px] max-h-[443px] overflow-hidden flex justify-center items-center">
                    <img src={fileUrl} alt="" onError={handleImageError} width="664" height="443" className="w-full h-full relative overflow-hidden" />
                </div>
                <figcaption className="Media_caption text-[1.3rem] text-[#717b8e] leading-3 mt-3 text-center"> 
                    {fileDescription ? fileDescription : ""}
                </figcaption>
            </figure>
            <article className="Article_content mt-7 mb-4">
                {updatedArticle.content ? (
                    <RenderHtmlString htmlString={content} />
                ) : (
                    <></>
                )}
            </article>
        </div>
    );
}

export default PB_content;